<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { productTypeList } from '@/utils/textFile'
export default {
  data() {
    return {
      ...api.command.getState(),
      // 店铺搜索
      storeData: [],
      detail: {},
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/farmBalance/detail/${id}`,
        })
        .then((result) => {
          this.detail.usedType = result.usedType ? result.usedType.split(',') : []
          this.detail.shopIds = result.shopIds ? result.shopIds.split(',') : []
          this.$forceUpdate()
        })
    this.storeSearch()
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '券名(12字符内)',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 12,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '金额',
            type: 'inputNumber',
            cols: 12,
            key: 'amount',
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            name: '类型',
            type: 'radioButton',
            cols: 24,
            key: 'balanceType',
            defaultValue: '0',
            disabled: this.$route.query.id,
            typeData: [
              {
                name: '余额券',
                value: '0',
              },
              {
                name: '余额卡',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    getForm2() {
      return {
        title: '使用对象',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            name: '券针对产品类型',
            type: 'checkBoxButton',
            key: 'usedType',
            cols: 24,
            typeData: productTypeList(),
            rules: [
              {
                required: true,
                type: 'array',
              },
            ],
          },
        ],
      }
    },
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    getForm3() {
      return {
        title: '使用对象',
        type: 'cardForm',
        data: [
          {
            name: '券针对商家',
            cols: 4,
            rules: [
              {
                required: true,
              },
            ],
            type: 'radioButton',
            key: 'shopType',
            typeData: [
              {
                name: '所有商家',
                value: '0',
              },
              {
                name: '指定商家',
                value: '1',
              },
            ],
            display: true,
          },
          {
            name: '选择店铺',
            type: 'dropDownMultiInput',
            cols: 12,
            key: 'mainBodyName',
            onInputSearch: this.storeSearch,
            typeData: this.storeData,
            valueKey: 'shopIds',
            inputProps: {
              addonBefore: <a-icon type="search" />,
            },
            display: this.detail.shopType == '1',
          },
        ].filter((e) => e.display),
      }
    },
    getForm4() {
      return {
        title: '余额卡发放',
        type: 'cardForm',
        data: [
          {
            name: '初始数量',
            type: 'input',
            cols: 12,
            key: 'balanceNum',
            rules: [
              {
                required: true,
              },
              {
                validator:(rule, value, callback)=>{
                   if (value){
                    const r = /^[0-9]*$/;
                    if (!r.test(value)){
                        callback('格式不正确')
                        return
                    }
                    callback()
                   }
                },
              },
            ],
          },
        ],
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            api.command[data.id ? 'editPost' : 'create']
              .call(this, {
                url: `/farmBalance/${data.id ? 'edit' : 'save'}`,
                params: {
                  ...data,
                  shopIds: data.shopIds && data.shopIds.length ? data.shopIds.join(',') : '',
                  usedType: data.usedType.join(','),
                },
                isPost: false,
              })
              .then((result) => {
                this.$router.push('/marketingCenter/balance')
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/marketingCenter/balance'),
        },
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPut.call(this, {
              url: `/base/farmCouponRule/del?ids=${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/balance')
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.detail.balanceType == '1' && this.getForm4(), this.getForm2(), this.getForm3()]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
